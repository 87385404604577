<template>
  <twitter-tab
    :key="forceUpdateKey"
    :items="twitterItems"
    :should-show-delete-button="true"
    @favorite-updated="refreshFavorites"
  />
</template>

<script>
  import TwitterTab from '../components/TwitterTab.vue'
  // TableButtonコンポーネントをインポート

  export default {
    metaInfo: {
      title: 'Favorite',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?3',
        },
      ],
    },

    components: {
      TwitterTab,
    },
    data () {
      return {
        tabid: null, // デフォルトのタブを選択します。ここを変えることでデフォルトタブを変更できます。
        twitterItems: [],
        dataFetched: false, // データの取得が完了したかどうかを示すフラグ
        forceUpdateKey: 0, // 追加
        dialog: false, // ダイアログの表示状態
        shouldShowDeleteButton: true,
      }
    },
    watch: {
      // '$route.params'の変更を監視しています
      '$route.params': {
        immediate: true,
        handler (newParams) {
          this.tabid = Number(newParams.tabid) || 0
        },
      },
    },

    created: async function () {
      try {
        // 各サービスのデータを取得します
        await Promise.all([
          this.fetchData('twitter'),
        ])
        this.dataFetched = true // データの取得が完了したのでフラグをtrueに設定
      } catch (e) {
        console.error('データの取得に失敗しました:', e)
      }
    },
    methods: {
      // 各サービスのデータを取得するメソッドを共通化
      fetchData (service) {
        try {
          // localStorageからお気に入りのデータを取得
          const favorites = JSON.parse(localStorage.getItem('favorites') || '[]')

          // timestampを基に降順に並び替え
          this[`${service}Items`] = favorites.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        } catch (e) {
          console.error(`${service}のデータの取得に失敗しました:`, e)
        }
      },
      refreshFavorites () {
        this.fetchData('twitter')
        this.forceUpdateKey += 1
      },
      clearFavorites () {
        // ダイアログを表示
        this.dialog = true
      },

      confirmClearFavorites () {
        // localStorageからお気に入りのデータをクリア
        localStorage.removeItem('favorites')
        // データを再取得（空になるはず）
        this.fetchData('twitter')
        this.forceUpdateKey += 1
        // ダイアログを閉じる
        this.dialog = false
      },
      safeRender (item, key) {
        // 配列の場合、joinメソッドを安全に呼び出す
        if (Array.isArray(item[key])) {
          return item[key].join(', ')
        }
        // nullまたはundefinedでない場合、値をそのまま返す
        return item[key] != null ? item[key] : ''
      },

      downloadFavorites () {
        // お気に入りデータを取得
        const favorites = JSON.parse(localStorage.getItem('favorites') || '[]')

        // 指定された項目をtab区切りの形式で整形
        const tabSeparatedData = favorites.map(item => {
          return [
            item.user_name || '',
            item.tweet_url || '',
            (item.adress || []).join(', '), // ここを修正
            item.size || '',
            item.aetheryteJp || '',
            item.aetheryteEn || '',
            item.aetheryteKo || '',
          ].join('\t')
        }).join('\n')

        // ヘッダーを追加
        const header = 'user_name\ttweet_url\tadress\tsize\taetheryteJp\taetheryteEn\taetheryteKo\n'
        const utf8Encoder = new TextEncoder('utf-8')
        const tabSeparatedDataUTF8 = utf8Encoder.encode(header + tabSeparatedData)

        const blob = new Blob([tabSeparatedDataUTF8], { type: 'text/tab-separated-values; charset=utf-8' })

        // ダウンロード処理
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'favorites.tsv' // .tsv拡張子を使用
        link.click()
        URL.revokeObjectURL(link.href)
      },
    },
  }
</script>
